import React from "react"

import HeaderLogo from "../components/HeaderLogo"
import DataPoints from "../assets/data-points.svg"
import Notifications from "../assets/notifications.svg"
import Started from "../assets/started.svg"
import Monitor from "../assets/monitor.svg"
import Collaboration from "../assets/collaboration.svg"

export default function IndexPage() {
  return (
    <div>
      <div className="w-full bg-red-100">
        <div className="py-8 mx-auto mb-4 bg-red-100 max-w-screen-xl">
          <nav>
            <div className="flex items-center justify-between px-8 mx-auto">
              <HeaderLogo />

              {/*
              <div>
                <a
                  href="//dashboard.gql-metrics.app"
                  className="px-3 py-2 text-sm text-red-800 rounded hover:bg-red-200 hover:underline"
                >
                  Go to app
                </a>
              </div>
              */}
            </div>
          </nav>

          <div className="flex flex-col items-center flex-1 w-4/5 mx-auto text-center">
            <h1 className="mt-8 text-3xl font-extrabold leading-tight text-gray-900 md:text-5xl">
              <span className="text-primary">Advanced</span> metrics,{" "}
              <span className="text-primary">easily</span> added, for your{" "}
              <span className="text-primary">GraphQL</span> API
            </h1>

            <DataPoints className="w-48 h-48 mx-4 md:h-80 md:w-80" />
          </div>

          <div className="text-center">
            <RequestAccessButton />
          </div>
        </div>
      </div>

      <FeatureSummary
        title="Keep track of your schema history with Changelog"
        body={
          <>
            Having multiple teams working against the same GraphQL schema? With{" "}
            <b>Changelog</b> you get a list of all changes that have happened in
            your schema - right in the inbox or on Slack.
          </>
        }
        illustration={Collaboration}
      />

      <FeatureSummary
        title="Deploy and observe deprecated fields"
        body={
          <>
            After deprecating a field it can become hard to know when it’s safe
            to remove it all together. With GraphQL Metrics you can see a list
            of all your fields on a schema, how much they’re being used and by
            which clients so that you can remove any deprecated fields safely.
          </>
        }
        illustration={Monitor}
      />

      <FeatureSummary
        title="Get started in minutes"
        body={
          <>
            GraphQL Metrics is really easy to get started. Simply sign up for an
            account and start measuring performance within minutes.
          </>
        }
        illustration={Started}
      />

      <FeatureSummary
        title="Identify and fix any slow queries"
        body={
          <>
            Got a query that’s making multiple N+1 queries? GraphQL Metrics
            helps you identify the queries that has the biggest negative impact
            on your performance and lets you drill down to finding the
            underlying issue, in everything from N+1 to slow resolve times.
          </>
        }
        illustration={Notifications}
      />

      <RequestAccessForm />

      <footer className="py-8 mt-12 bg-gray-900">
        <div className="px-8 mx-auto max-w-screen-lg">
          <span className="text-sm leading-relaxed text-gray-200">
            Made in Stockholm, Sweden
          </span>
        </div>
      </footer>
    </div>
  )
}

function FeatureSummary({ title, body, illustration: Illustration }) {
  return (
    <div className="py-8 mb-4 bg-white">
      <div className="px-8 mx-auto max-w-screen-lg">
        <div className="flex flex-col-reverse md:items-center md:flex-row">
          <div className="md:w-2/3 md:mr-16">
            <h2 className="mb-2 text-2xl font-bold leading-tight text-gray-800">
              {title}
            </h2>

            <p className="text-gray-800">{body}</p>
          </div>

          <Illustration className="w-56 h-48 mx-auto md:h-40 md:w-1/3" />
        </div>
      </div>
    </div>
  )
}

function RequestAccessButton() {
  return (
    <a
      href="#request-access"
      className="px-4 py-3 mx-auto antialiased italic font-bold leading-none text-center text-gray-100 bg-gray-900 rounded-full small text-md hover:bg-gray-800"
    >
      Sign up for beta access
    </a>
  )
}

function RequestAccessForm(props) {
  return (
    <div {...props} className="w-full mx-auto md:w-form">
      <h2 className="px-2 py-2 mb-4 text-3xl font-light leading-none text-center text-gray-800 md:px-0">
        Sign up for beta access
      </h2>

      <form
        id="request-access"
        className="flex flex-row items-center justify-center w-2/3 mx-auto md:w-form"
        name="contact"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <input type="hidden" name="form-name" value="contact" />

        <div className="flex flex-col w-full mb-4 mr-4">
          <label
            className="text-sm font-semibold text-gray-700 uppercase"
            htmlFor="email"
          >
            Email address
          </label>

          <input
            className="py-3"
            required
            type="email"
            name="email"
            placeholder="john@gql-metrics.app"
          />
        </div>

        <div className="text-center">
          <input
            type="submit"
            value="Request"
            className="block px-3 py-2 text-sm antialiased leading-none text-white uppercase bg-gray-900 rounded-full cursor-pointer hover:bg-gray-800"
          />
        </div>
      </form>
    </div>
  )
}
