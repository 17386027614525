import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

export default function HeaderLogo(props) {
  const data = useStaticQuery(graphql`
    query {
      logotype: file(relativePath: { eq: "header-text.png" }) {
        childImageSharp {
          fixed(height: 40) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <Img
      {...props}
      className="h-full"
      fixed={data.logotype.childImageSharp.fixed}
    />
  )
}
